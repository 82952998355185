import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Grid,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Button,
  Switch,
  FormControlLabel,
  FormGroup,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

// Components
import FilterBar from "../components/FilterBar";
import ActionBar from "../components/ActionBar";
import StatsBar from "../components/StatsBar";
import TableWrapper from "../components/TableWrapper";

// Utils
import { validateEmail } from "../utils/helper";
import {
  AdduserAPI,
  searchUserAPI,
  changeUserPermission,
} from "../utils/apicalls";

export default function UserView() {
  const [email, setEmail] = useState("");
  const [userStatus, setuserStatus] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSearch, setopenSearch] = useState(false);
  const [newEmail, setnewEmail] = useState("");
  const [userType, setUserType] = useState([]);
  const [permission, setPermission] = useState({
    wa_web: false,
    logs: false,
    wa_stat: true,
    // admin_stat: false,
  });
  const [userFilter, setuserFilter] = useState({});

  const [PageSize, setPageSize] = useState(10);
  const [dataCount, setdataCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState(false);
  const [dialogBox, setdialogBox] = useState(false);

  const [userPermission, setuserPermission] = useState({
    email: "",
    permission: { wa_web: false, logs: false },
  });

  const StatOptions = [{ name: "Total Users", method: dataCount }];

  const TableMap = [
    { column: "Email", mapping: "email" },
    { column: "Password", mapping: "password" },
    { column: "User Type", mapping: "userType" },
    { column: "Date", mapping: "createdAt" },
    { column: "Status", mapping: "isActive" },
    { column: "Action", mapping: "button", renderer: handleTableAction },
  ];

  const FilterOptions = [
    { name: "Search", method: handleSearch },
    { name: "Reset", method: handleReset },
  ];
  const ActionOptions = [{ name: "Add User", method: handleAddUserButton }];
  const statusOption = ["Deactive", "Active"];
  const userTypeOption = ["Admin"];

  if (userStatus === null) setuserStatus([]);

  const handleChange = (event) => {
    setPermission({
      ...permission,
      [event.target.name]: event.target.checked,
    });
  };

  function handleTableAction(item) {
    return (
      <>
        {item.isActive === true ? (
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ m: 1 }}
              disabled={item.status === "active" ? true : false}
              onClick={() => {
                deactivateUser(item.email);
              }}
            >
              DEACTIVATE
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClickdialogBox(item.email, item.permission);
              }}
            >
              <EditIcon />
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              size="small"
              color="success"
              sx={{ m: 1, p: 1 }}
              disabled={item.status === "active" ? true : false}
              onClick={() => {
                activateUser(item.email);
              }}
            >
              &nbsp;&nbsp; &nbsp;&nbsp;ACTIVATE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          </>
        )}
      </>
    );
  }
  function handleClickdialogBox(email, permission) {
    setdialogBox(true);
    setuserPermission({ email, permission });
  }

  if (PageSize === null) setPageSize(10);

  async function callApi() {
    const obj = { email: "", isActive: "" };

    if (Object.entries(userFilter).length) {
      const { data, count } = await searchUserAPI(
        userFilter,
        (currentPage - 1) * PageSize,
        PageSize
      );
      setTableData(data);
      setdataCount(count);
      return;
    }

    const { data, count } = await searchUserAPI(
      obj,
      (currentPage - 1) * PageSize,
      PageSize
    );
    setTableData(data);
    setdataCount(count);
  }

  useEffect(
    () => {
      callApi();
    },
    // eslint-disable-next-line
    [userFilter, PageSize, currentPage, status]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [dataCount, PageSize, userPermission]);

  async function UpdateEmailpermission() {
    const newPermission = {
      email: userPermission.email,
      ...userPermission.permission,
    };
    await changeUserPermission(newPermission);
    setdialogBox(false);

    callApi();
  }

  async function activateUser(email) {
    await changeUserPermission({ email, isActive: true });
    status === "activated" ? setStatus("deactivated") : setStatus("activated");
  }

  async function deactivateUser(email) {
    await changeUserPermission({ email, isActive: false });
    status === "deactivated"
      ? setStatus("activated")
      : setStatus("deactivated");
  }

  const handleClose = () => {
    setOpen(false);
    setPermission({
      wa_web: false,
      logs: false,
      // admin_stat: false,
    });
    setnewEmail("");
  };

  async function handleAddUser() {
    let obj = {
      email: "",
      isActive: true,
      userType: userType.length ? userType : "Admin",
    };

    if (newEmail && validateEmail(newEmail, setnewEmail)) obj.email = newEmail;
    if (!obj.email) return setOpen(true);

    obj.password = Math.random().toString(36).substring(2, 10);
    obj.permission = permission;

    const res = await AdduserAPI(obj);
    if (res.status === 200) {
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setPermission({ wa_web: false, logs: false });
    setnewEmail("");
  }

  async function handleSearchInternal() {
    let obj = { email: "", isActive: "" };
    if (userStatus.length)
      obj.isActive = userStatus === "Active" ? true : false;
    if (email && validateEmail(email, setEmail)) obj.email = email;
    if (obj.email || obj.hasOwnProperty("isActive")) setuserFilter(obj);
    setopenSearch(false);
  }

  function handleSearch() {
    setopenSearch(true);
  }

  function handleAddUserButton() {
    setOpen(true);
  }

  async function handleReset() {
    setuserStatus([]);
    setEmail("");
    setuserFilter({});
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <Grid container sx={{ px: 6 }}>
          <FilterBar FilterOptions={FilterOptions} />
          <ActionBar ActionOptions={ActionOptions} />
          <StatsBar StatOptions={StatOptions} />
          <TableWrapper
            columnMap={TableMap}
            data={tableData}
            count={dataCount}
            currentPage={currentPage}
            pageSize={PageSize}
            setSize={setPageSize}
            setPage={setCurrentPage}
          />
          <Dialog
            open={dialogBox}
            onClose={() => setdialogBox(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Edit User Permission
            </DialogTitle>

            <DialogContent>
              <Grid
                container
                sx={{
                  px: 5,
                  py: 3,
                  flexDirection: "column",
                  display: "flex",
                  overflow: "auto",
                }}
              >
                <FormControl
                  component="fieldset"
                  variant="standard"
                  sx={{ width: "100%" }}
                >
                  <FormLabel component="legend">User Permissions</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      label="Whatsapp Web"
                      control={
                        <Switch
                          checked={userPermission?.permission?.wa_web}
                          onChange={() =>
                            setuserPermission({
                              ...userPermission,
                              permission: {
                                wa_web: userPermission.permission.wa_web
                                  ? false
                                  : true,
                                logs: userPermission.permission.logs,
                              },
                            })
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      label="Logs"
                      control={
                        <Switch
                          checked={userPermission?.permission?.logs}
                          onChange={() =>
                            setuserPermission({
                              ...userPermission,
                              permission: {
                                logs: userPermission.permission.logs
                                  ? false
                                  : true,
                                wa_web: userPermission.permission.wa_web,
                              },
                            })
                          }
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setdialogBox(false)}>Cancel</Button>
              <Button onClick={UpdateEmailpermission} autoFocus>
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>

      {/* Add User Dialog */}
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add User</DialogTitle>

        <DialogContent>
          <Grid
            container
            sx={{
              px: 5,
              py: 3,
              flexDirection: "column",
              display: "flex",
              overflow: "auto",
            }}
          >
            <Grid item lg={12}>
              <TextField
                label="Email"
                id="newEmail"
                size="small"
                sx={{ p: 1, width: "100%" }}
                value={newEmail || ""}
                onChange={(event) => {
                  setnewEmail(event.target.value);
                }}
              />
            </Grid>
            <Grid item lg={12}>
              <Autocomplete
                id="userType"
                size="small"
                options={userTypeOption}
                value={userType}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setUserType(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ p: 1, width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="User Type"
                  />
                )}
              />
            </Grid>
            <Grid item lg={12}>
              <FormControl
                component="fieldset"
                variant="standard"
                sx={{ width: "100%", px: 1 }}
              >
                <FormLabel component="legend">Permissions</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    label="Whatsapp Web"
                    labelPlacement="end"
                    control={
                      <Switch
                        checked={permission.wa_web || false}
                        onChange={handleChange}
                        name="wa_web"
                      />
                    }
                  />
                  <FormControlLabel
                    label="Logs"
                    labelPlacement="end"
                    control={
                      <Switch
                        checked={permission.logs || false}
                        onChange={handleChange}
                        name="logs"
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddUser} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Search Dialog*/}
      <Dialog
        open={openSearch}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filters</DialogTitle>

        {/* webfilter Options */}
        <Box
          px={5}
          sx={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 1, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  id="userFiltername"
                  label="Email"
                  sx={{ width: "100%" }}
                  size="small"
                  error={email.length > 20 ? true : false}
                  helperText={email.length > 20 ? "Invalid email" : ""}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Autocomplete
                  id="userType"
                  size="small"
                  options={statusOption}
                  value={userStatus}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  getOptionLabel={(option) =>
                    typeof option === "string" || option instanceof String
                      ? option
                      : ""
                  }
                  onChange={(_, value) => {
                    setuserStatus(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%" }}
                      {...params}
                      variant="outlined"
                      label="User Status"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => setopenSearch(false)}>Cancel</Button>
          <Button onClick={handleSearchInternal} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
