import { toast } from "react-toastify";

function validateMobile(mobile) {
  return (
    !mobile ||
    (mobile.length === 10 && mobile >= "6000000000" && mobile <= "9999999999")
  );
}

function validateMobileToast(mobile) {
  if (validateMobile(mobile)) return mobile;
  else {
    toast.error("Invalid Mobile");
    return "";
  }
}

function validateDeviceid(deviceID) {
  return (deviceID >= 1000 && deviceID <= 9999) || !deviceID;
}

function validateDeviceidToast(deviceID) {
  if (validateDeviceid(deviceID)) return deviceID;
  toast.error("Invalid Device ID");
}

function validateEmail(value, state) {
  // eslint-disable-next-line
  const re =
    /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!re.test(value)) {
    toast.error("Invalid Email");
    return state("");
  } else return true;
}

function validateDate(fromDate, toDate) {
  if (!fromDate && toDate) {
    toast.error("From date is required");
    return false;
  }
  if (fromDate && toDate) {
    if (fromDate > toDate) {
      toast.error("From date should be less than To date");
    }
  }
  return true;
}

export {
  validateMobile,
  validateMobileToast,
  validateEmail,
  validateDate,
  validateDeviceid,
  validateDeviceidToast,
};
