import React from "react";
import { Grid, Box, Fab } from "@mui/material";

//Imported Icons
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";

export default function FilterBar(props) {
  function decideIcon(item) {
    switch (item) {
      case "Running":
        return <CheckIcon sx={{ mr: 1 }} />;
      case "Search":
        return <SearchIcon sx={{ mr: 1 }} />;
      case "Reset":
        return <RestartAltIcon sx={{ mr: 2 }} />;
      case "Export":
        return <FileDownloadIcon sx={{ mr: 1 }} />;
      default:
        return;
    }
  }
  return (
    <Box sx={{ flexGrow: 1, width: "100%", pt: 3, pb: 1 }}>
      <Grid container spacing={2} justifyContent="end">
        {props.FilterOptions.map((item, index) => (
          <Grid key={index} item md={4} lg={2} xl={1}>
            <Fab
              variant="extended"
              size="small"
              color="primary.main"
              sx={{ width: "100%" }}
              onClick={item.method}
            >
              {decideIcon(item.name)}
              {item.name}
            </Fab>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
