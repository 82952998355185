import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Grid,
  Chip,
  Fab,
  Autocomplete,
  Typography,
  Modal,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";

// Icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OfficeIcon from "../assets/icons/office.png";
import WhatsAppIcon from "../assets/icons/whatsapp.png";
import MailIcon from "../assets/icons/gmail.png";
import WhatsAppIcon2 from "../assets/icons/whatsappbanned.png";
import MailIcon2 from "../assets/icons/gmailbanned.png";
import InstagramIcon2 from "../assets/icons/instagrambanned.png";
import FacebookIcon2 from "../assets/icons/facebookbanned.png";
import LinkedInIcon2 from "../assets/icons/linkedinbanned.png";
import InstagramIcon from "../assets/icons/instagram.png";
import FacebookIcon from "../assets/icons/facebook.png";
import LinkedInIcon from "../assets/icons/linkedin.png";

// Components
import FilterBar from "../components/FilterBar";
import StatsBar from "../components/StatsBar";
import TableWrapper from "../components/TableWrapper";

// Utils
import { validateMobile, validateMobileToast } from "../utils/helper";
import { getMobileOtp, searchWeb, banNumberApi } from "../utils/apicalls";

export default function HomeView() {
  const [mobile, setMobile] = useState("");
  const [mobileStatus, setMobileStatus] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [webfilter, setwebfilter] = useState({});
  const [open, setOpen] = useState(false);
  const [webPageSize, setwebPageSize] = useState(10);
  const [webdataCount, setwebDataCount] = useState();
  const [webCurrentPage, setCurrentPage] = useState(1);
  const [webTable, setwebTable] = useState([]);
  const [platformStatus, setPlatformStatus] = useState([]);
  const [updatewpStatus, setupdatewpStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [updateMobilewaStatus, setUpdateMobilewaStatus] = useState("");
  const [searchplatformStatus, setSearchplatformStatus] = useState([]);
  const [searchedIcon, setSearchedIcon] = useState([
    "whatsApp",
    "Facebook",
    "linkedIn",
    "Instagram",
    "G-mail",
    "Office",
  ]);

  const FilterOptions = [
    { name: "Search", method: handleSearch },
    { name: "Reset", method: handleReset },
  ];

  const StatOptions = [
    {
      name: "Total Count",
      method: webdataCount,
    },
    {
      name: "Results",
      method: decideIcon(),
      color: "white",
    },
  ];

  const TableMap = [
    { column: "Name", mapping: "name" },
    { column: "Mobile", mapping: "mobile" },
    { column: "Mobile Status", mapping: "inactive" },
    {
      column: "Status",
      mapping: "status",
      renderer: tableStatusRender,
      disabled: typeof searchedIcon === "object",
    },
    {
      column: "OTP",
      mapping: "none",
      renderer: tableOtpRender,
      disabled:
        typeof searchedIcon === "object" ||
        searchplatformStatus === "used" ||
        searchplatformStatus === "banned",
    },
    {
      column: "Platform",
      mapping: "none",
      renderer: tablePlatformRender,
      disabled: typeof searchedIcon !== "object",
    },
  ];

  const platformStatusOption = ["used", "unused", "banned"];
  const mobileStatusOption = ["active", "inactive"];
  const platformOption = [
    "whatsApp",
    "Facebook",
    "linkedIn",
    "Instagram",
    "G-mail",
    "Office",
  ];

  if (mobileStatus === null) setMobileStatus([]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  if (webPageSize === null) setwebPageSize(10);
  if (platform === null) setPlatform([]);
  if (mobileStatus === null) setMobileStatus([]);
  if (platformStatus === null) setPlatformStatus([]);

  async function callApi() {
    const obj = {
      email: "",
      inactive: "",
      status: "",
      mobile: "",
      platform: [],
    };
    if (typeof platform === "object")
      setSearchedIcon([
        "whatsApp",
        "Facebook",
        "linkedIn",
        "Instagram",
        "G-mail",
        "Office",
      ]);
    if (Object.entries(webfilter).length) {
      const { data, count } = await searchWeb(
        webfilter,
        (webCurrentPage - 1) * webPageSize,
        webPageSize
      );
      setwebTable(data);
      setwebDataCount(count);
    } else {
      const { data, count } = await searchWeb(
        obj,
        (webCurrentPage - 1) * webPageSize,
        webPageSize
      );
      setwebTable(data);
      setwebDataCount(count);
    }
  }

  useEffect(() => {
    callApi();
    // eslint-disable-next-line
  }, [webfilter, webPageSize, webCurrentPage, updatewpStatus]);

  useEffect(() => {
    setCurrentPage(1);
  }, [webdataCount, webPageSize, webfilter]);

  function handleMobile(event) {
    setMobile(event.target.value.replace(/\D/g, ""));
  }

  async function handleSearchInternal() {
    let obj = {
      email: "",
      inactive: "",
      status: "",
      mobile: "",
      platform: [],
    };

    if (platformOption.includes(platform))
      obj.platform = platform.length ? [platform] : [];
    if (platformStatus)
      obj.status = platformStatus.length ? platformStatus : "";
    setSearchedIcon(platform);
    setSearchplatformStatus(platformStatus);
    if (validateMobileToast(mobile)) {
      obj.mobile = mobile;
      setSearchedIcon([
        "whatsApp",
        "Facebook",
        "linkedIn",
        "Instagram",
        "G-mail",
        "Office",
      ]);
      setPlatform([]);
      setMobileStatus([]);
    }

    if (mobileStatus.length) {
      obj.inactive = mobileStatus === "active" ? "false" : "true";
      obj.platform = [];
      obj.status = "";
      setSearchedIcon([
        "whatsApp",
        "Facebook",
        "linkedIn",
        "Instagram",
        "G-mail",
        "Office",
      ]);
      setPlatform([]);
      setMobileStatus([]);
    }
    setwebfilter(obj);
    setOpen(false);
  }

  async function handleSearch() {
    setOpen(true);
  }

  async function handleReset() {
    window.location.reload();
  }

  function banUsedNumber(mobile) {
    setModal(true);
    setUpdateMobilewaStatus(mobile);
    setupdatewpStatus("");
  }

  async function banNumber() {
    await banNumberApi(updateMobilewaStatus, platform);
    setupdatewpStatus("changed");
    setModal(false);
  }

  const decideColor = (state) => {
    if (state === "used") return "primary";
    if (state === "unused") return "success";
    return "error";
  };

  async function getOtp(mobile, platform) {
    await getMobileOtp(mobile, platform);
  }

  function decideIcon() {
    if (searchedIcon === "whatsApp")
      return <img src={WhatsAppIcon} height={25} alt="whatsapp" />;
    if (searchedIcon === "Instagram")
      return <img src={InstagramIcon} height={25} alt="Instagram" />;
    if (searchedIcon === "linkedIn")
      return <img src={LinkedInIcon} height={25} alt="LinkedIn" />;
    if (searchedIcon === "Facebook")
      return <img src={FacebookIcon} height={25} alt="Facebook" />;
    if (searchedIcon === "G-mail")
      return <img src={MailIcon} height={25} alt="G-mail" />;
    if (searchedIcon === "Office")
      return <img src={OfficeIcon} height={25} alt="Office" />;
    else
      return (
        <>
          <img src={WhatsAppIcon} height={25} alt="whatsapp" />
          <img src={InstagramIcon} height={25} alt="Instagram" />
          <img src={FacebookIcon} height={25} alt="Facebook" />
          <img src={MailIcon} height={25} alt="G-mail" />
          <img src={LinkedInIcon} height={25} alt="LinkedIn" />
          <img src={OfficeIcon} height={25} alt="Office" />
        </>
      );
  }

  function tableStatusRender(item) {
    return (
      <Chip
        label={
          item.platform[searchedIcon]?.status === "used" ? (
            <Button
              style={{ background: "none" }}
              onClick={() => {
                banUsedNumber(item.mobile);
              }}
              variant=""
              endIcon={<ArrowForwardIosIcon />}
            >
              {item.platform[searchedIcon]?.status}
            </Button>
          ) : (
            item.platform[searchedIcon]?.status
          )
        }
        color={decideColor(item.platform[searchedIcon]?.status)}
        variant="outlined"
      />
    );
  }

  function tableOtpRender(item) {
    return (
      <Fab
        key={item.mobile}
        variant="extended"
        size="small"
        color="success"
        sx={{
          width: "100%",
          textAlign: "center",
          fontSize: "12px",
        }}
        onClick={() => {
          getOtp(item.mobile, platform);
        }}
      >
        <nobr>Get Otp</nobr>
      </Fab>
    );
  }

  function tablePlatformRender(item) {
    const { platform } = item;
    const icons = {
      whatsApp: {
        used: WhatsAppIcon,
        banned: WhatsAppIcon2,
        alt: "Whatsapp",
      },
      Instagram: {
        used: InstagramIcon,
        banned: InstagramIcon2,
        alt: "Instagram",
      },
      linkedIn: {
        used: LinkedInIcon,
        banned: LinkedInIcon2,
        alt: "LinkedIn",
      },
      Facebook: {
        used: FacebookIcon,
        banned: FacebookIcon2,
        alt: "Facebook",
      },
      "G-mail": {
        used: MailIcon,
        banned: MailIcon2,
        alt: "G-mail",
      },
      Office: {
        used: OfficeIcon,
        banned: OfficeIcon,
        alt: "Office",
      },
    };

    let renderedIcons = Object.entries(platform).map(([key, value]) => {
      if (value.status !== "unused") {
        return (
          <img
            key={key}
            src={icons[key][value.status]}
            height={25}
            alt={icons[key].alt}
          />
        );
      } else {
        return null;
      }
    });

    if (!renderedIcons.some((icon) => icon !== null))
      renderedIcons = <i>Unused</i>;

    return <>{renderedIcons}</>;
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <Grid container sx={{ px: 6 }}>
          <FilterBar FilterOptions={FilterOptions} />
          <StatsBar StatOptions={StatOptions} />
          <TableWrapper
            columnMap={TableMap}
            data={webTable}
            count={webdataCount}
            currentPage={webCurrentPage}
            pageSize={webPageSize}
            setSize={setwebPageSize}
            setPage={setCurrentPage}
          />
        </Grid>
      </Box>

      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filters</DialogTitle>

        {/* webfilter Options */}
        <Box
          px={5}
          sx={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Mobile Number"
                sx={{ width: "100%" }}
                size="small"
                type="number"
                disabled={typeof mobileStatus !== "object" ? true : false}
                error={!validateMobile(mobile)}
                helperText={validateMobile(mobile) ? "" : "Invalid Mobile"}
                value={mobile}
                onChange={handleMobile}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disabled={mobile ? true : false}
                id="action"
                size="small"
                options={mobileStatusOption}
                value={mobileStatus}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setMobileStatus(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="Mobile Status"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disabled={
                  mobile || typeof mobileStatus !== "object" ? true : false
                }
                id="action"
                size="small"
                options={platformOption}
                value={platform}
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option[0].toUpperCase() + option.slice(1).toLowerCase()
                    : ""
                }
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(_, value) => {
                  setPlatform(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="Platform"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disabled={
                  mobile || typeof mobileStatus !== "object"
                    ? true
                    : platformOption.includes(platform)
                    ? false
                    : true
                }
                id="action"
                size="small"
                options={platformStatusOption}
                value={platformStatus}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setPlatformStatus(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="Platform Status"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => handleSearchInternal()} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* ban number modal */}
      <Modal
        open={modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {updateMobilewaStatus}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 2 }}>
            Do you want to ban this mobile number ?
          </Typography>
          <Button
            onClick={() => {
              setModal(false);
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              banNumber();
            }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
    </>
  );
}
