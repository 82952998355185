export const dockerListUrl = "https://back-wa.ajx.me/wa/dockerList";
export const exportUrl = "https://back-wa.ajx.me/wa/download";
export const createUserUrl = "https://back-wa.ajx.me/user/createuser";
export const searchUser = "https://back-wa.ajx.me/user/search";
export const changeUserPermissionUrl =
  "https://back-wa.ajx.me/user/changePermission";
export const changePasswordUrl = "https://back-wa.ajx.me/user/changePassword";
export const LoginUrl = "https://back-wa.ajx.me/login";
export const LogsUrl = "https://back-wa.ajx.me/logs";
export const NumListUrl = "https://back-wa.ajx.me/web/numList";
export const searchWebUrl = "https://back-wa.ajx.me/smNum/search";
export const OtpUrl = "https://back-wa.ajx.me/smNum/getOtp";
export const bannedNumUrl = "https://back-wa.ajx.me/smNum/bannedNum";
export const cryptoSecret = "lsdjfe1*2iofs$#re2365-oejf#klse&?LldDG652";
export const getIpAddressUrl = "https://back-wa.ajx.me/server/getiplist";
export const addServerUrl = "https://back-wa.ajx.me/server/addserver";
export const searchServerUrl = "https://back-wa.ajx.me/server/search";
export const editServerUrl = "https://back-wa.ajx.me/server/deviceUpdate";
export const changeServerStatusUrl = "https://back-wa.ajx.me/server/serverAction";
export const imageCounterUrl = "https://back-wa.ajx.me/wa/imagecount";
