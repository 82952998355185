import React from "react";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  Pagination,
  TextField,
  Skeleton,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function TableWrapper(props) {
  const pageSizeOption = ["10", "25", "50", "100", "200", "500"];

  // Error Handling for Data
  if (props.count === 0) return renderNoData();
  if (!props.count) return renderLoading();

  function renderLoading() {
    return (
      <Box sx={{ flexGrow: 1, width: "100%", py: 2 }}>
        <Box
          sx={{
            width: "100%",
            maxHeight: "600px",
            display: "block",
            overflowY: "auto",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {["Name", "Email", "Phone", "Platform"].map((row, index) => (
                    <TableCell key={index} sx={{ fontWeight: 700 }}>
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(7)].map((_, cindex) => (
                  <TableRow key={cindex}>
                    {[...Array(4)].map((_, rindex) => (
                      <TableCell key={rindex} component="th" scope="row">
                        <Skeleton animation="wave" variant="text" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }

  function renderNoData() {
    return (
      <Box
        sx={{
          my: 3,
          py: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100vw",
          bgcolor: "lightgray",
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          No Data Found
        </Typography>
      </Box>
    );
  }

  function handleChangePage(event, newpage) {
    props.setPage(newpage);
  }

  function handleChangeRowsPerPage(newrpg) {
    if (!newrpg) return;
    let newrpgInt = parseInt(newrpg);
    if (!newrpgInt) newrpgInt = 10;
    props.setSize(newrpgInt);
  }

  const filteredColumnMap = props.columnMap.filter((obj) => !obj.disabled);

  let customColumns = [];
  let chipColumns = [];
  const columns = filteredColumnMap.reduce((acc, mapping, index) => {
    if (/status/i.test(mapping.column)) chipColumns.push(index);
    if (mapping.renderer) customColumns.push(index);
    acc.push(mapping.column);
    return acc;
  }, []);

  const lookup = filteredColumnMap.reduce((acc, mapping) => {
    acc[mapping.column] = mapping.mapping;
    return acc;
  }, {});

  const orderedData = props.data.map((row) =>
    Object.keys(lookup).map((column) => {
      const field = lookup[column];
      if (field === "inactive")
        return row[field] === "true" ? "Inactive" : "Active";
      if (field === "isActive")
        return row[field] === true ? "Active" : "Inactive";
      if (column === "Date") return row[field].slice(0, 10);
      return row[field];
    })
  );

  function autoColor(value) {
    if (!value) return "error";
    value = value.toString().toLowerCase();
    if (
      value === "removed" ||
      value === "inactive" ||
      value === "incomplete" ||
      !value
    )
      return "error";
    if (value === "stopped") return "secondary";
    if (value === "not working") return "warning";
    return "success";
  }

  function handleEachRecord(index, row, cell) {
    // Handle Button
    if (customColumns.includes(index))
      return (
        <TableCell key={index} align={"center"}>
          {filteredColumnMap[index].renderer(row, cell)}
        </TableCell>
      );
    // Handle Chip
    if (chipColumns.includes(index))
      return (
        <TableCell key={index} align={"center"}>
          <Chip
            label={cell ? cell : "N/A"}
            color={autoColor(cell)}
            variant="outlined"
          />
        </TableCell>
      );
    return <TableCell key={index}>{cell ? cell : "N/A"}</TableCell>;
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, width: "100%", py: 2 }}>
        <Box
          sx={{
            width: "100%",
            maxHeight: "600px",
            display: "block",
            overflowY: "auto",
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column}
                      align={
                        customColumns.includes(index) ||
                        chipColumns.includes(index)
                          ? "center"
                          : "left"
                      }
                      sx={{ fontWeight: "700" }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orderedData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td,	&:last-child th": { border: 0 } }}
                  >
                    {row.map((cell, columnindex) =>
                      handleEachRecord(columnindex, props.data[index], cell)
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {props.pageSize && props.count > props.pageSize ? (
        <Box sx={{ flexGrow: 1, width: "100%", pt: 2, pb: 1 }}>
          <Grid container spacing={2} justifyContent="end" alignItems="center">
            <Grid item>
              <Pagination
                className="pagination-bar"
                color="primary"
                count={Math.ceil(props.count / props.pageSize) || 1}
                page={props.currentPage}
                onChange={handleChangePage}
              />
            </Grid>
            <Grid item md={2} lg={1.2} xl={1.5} alignItems="center">
              <Autocomplete
                id="pageCount"
                size="small"
                options={pageSizeOption}
                value={props.pageSize}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  handleChangeRowsPerPage(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    size="small"
                    {...params}
                    variant="outlined"
                    label="Rows"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default TableWrapper;
