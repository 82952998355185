import { decodeToken } from "react-jwt";

const PrivateRoute = ({ children }) => {
  try {
    const token = window.localStorage.getItem("token");
    const decodedToken = decodeToken(token);
    const login = decodedToken.login;
    const userType = decodedToken.userType;

    if (decodedToken && login) {
      const path = window.location.pathname;
      const permissionArray = Object.entries(decodedToken.permission);

      for (let [key, value] of permissionArray) {
        if (key === "wa_stat" && path === "/automation" && value)
          return children;
        if (key === "admin_stat" && path === "/users" && value) return children;
        if (key === "logs" && path === "/log" && value) return children;
        if (key === "wa_web" && path === "/" && value) return children;
      }
      if (path === "/server" && userType === "SuperAdmin") return children;
      else {
        window.location.href = "/";
      }
    }

    throw new Error("");
  } catch (error) {
    window.location.href = "/signin";
    localStorage.removeItem("token");
  }
};

export default PrivateRoute;
