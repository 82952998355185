// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ---- reset ---- */
body {
  margin: 0;
  font: normal 75% Arial, Helvetica, sans-serif;
}

canvas {
  display: block;
  vertical-align: bottom;
}

/* ---- particles.js container ---- */
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #24d1f0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

/* ---- stats.js ---- */
.count-particles {
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13e8e9;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.js-count-particles {
  font-size: 1.1em;
}

#stats,
.count-particles {
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}

#stats {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.count-particles {
  border-radius: 0 0 3px 3px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,oBAAoB;AACpB;EACE,SAAS;EACT,6CAA6C;AAC/C;;AAEA;EACE,cAAc;EACd,sBAAsB;AACxB;;AAEA,qCAAqC;AACrC;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,4BAA4B;EAC5B,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA,uBAAuB;AACvB;EACE,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,yCAAyC;EACzC,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["input[type=\"number\"]::-webkit-inner-spin-button,\ninput[type=\"number\"]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* ---- reset ---- */\nbody {\n  margin: 0;\n  font: normal 75% Arial, Helvetica, sans-serif;\n}\n\ncanvas {\n  display: block;\n  vertical-align: bottom;\n}\n\n/* ---- particles.js container ---- */\n#particles-js {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-color: #24d1f0;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: 50% 50%;\n}\n\n/* ---- stats.js ---- */\n.count-particles {\n  background: #000022;\n  position: absolute;\n  top: 48px;\n  left: 0;\n  width: 80px;\n  color: #13e8e9;\n  font-size: 0.8em;\n  text-align: left;\n  text-indent: 4px;\n  line-height: 14px;\n  padding-bottom: 2px;\n  font-family: Helvetica, Arial, sans-serif;\n  font-weight: bold;\n}\n\n.js-count-particles {\n  font-size: 1.1em;\n}\n\n#stats,\n.count-particles {\n  -webkit-user-select: none;\n  margin-top: 5px;\n  margin-left: 5px;\n}\n\n#stats {\n  border-radius: 3px 3px 0 0;\n  overflow: hidden;\n}\n\n.count-particles {\n  border-radius: 0 0 3px 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
