import React from "react";
import { Button, Grid, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function ActionBar(props) {
  return (
    <Box sx={{ flexGrow: 1, width: "100%", py: 1 }}>
      <Grid container spacing={2} justifyContent="end">
        {props.ActionOptions.map((item, index) => (
          <Grid key={index} item md={4} lg={2} xl={1.5}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "100%", position: "static" }}
              onClick={item.method}
            >
              <AddIcon sx={{ mr: 1 }} />
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
