import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Button,
  Tooltip,
  MenuItem,
  Dialog,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { getUser, changePassword, getUserAllDetail } from "../utils/apicalls";

export function Navbar() {
  let pages = [
    { label: "Home", id: 1, path: "/" },
    { label: "Automation", id: 2, path: "/automation" },
    { label: "Logs", id: 3, path: "/log" },
    { label: "Users", id: 4, path: "/users" },
    { label: "Server", id: 5, path: "/server" },
  ];

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [oldPassword, setOldpassword] = useState("");
  const [newPassword, setNewpassword] = useState("");
  const [userName, setUserName] = useState("");
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [newPage, setnewPage] = useState(pages);
  const settings = ["Logout", "Change Password"];
  const [activeIndex, setActiveIndex] = useState(
    pages.find((e) => e.path === window.location.pathname).id - 1
  );

  async function getPageList() {
    const userData = await getUserAllDetail();
    const userType = userData.userType;
    const allPagesEntries = Object.entries(userData.permission);
    setUserName(userData.email);

    const allowedPages = allPagesEntries
      .filter((item) => item[1])
      .map((item) => {
        switch (item[0]) {
          case "wa_web":
            return { label: "Home", id: 1, path: "/" };
          case "wa_stat":
            return { label: "Automation", id: 2, path: "/automation" };
          case "logs":
            return { label: "Logs", id: 3, path: "/log" };
          case "admin_stat":
            return { label: "Users", id: 4, path: "/users" };
          default:
            return null;
        }
      })
      .filter((item) => item !== null);

    if (userType === "SuperAdmin")
      allowedPages.push({ label: "Server", id: 5, path: "/server" });
    setnewPage(allowedPages.sort((a, b) => a.id - b.id));
  }

  useEffect(
    () => {
      getPageList();
    },
    // eslint-disable-next-line
    []
  );

  const redirectPath = (item) => {
    if (item === "Home") navigate("/");
    if (item === "Automation") navigate("/automation");
    if (item === "Users") navigate("/users");
    if (item === "Logs") navigate("/log");
    if (item === "Server") navigate("/server");
  };

  const handleSetting = (setting) => {
    switch (setting) {
      case "Logout":
        handleLogout();
        break;
      case "Change Password":
        setOpen(true);
        break;
      default:
      // code block
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/signin";
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClose = () => {
    setOpen(false);
    setOldpassword("");
    setNewpassword("");
  };

  const updatePassword = async () => {
    if (oldPassword.length < 8 || newPassword.length < 8)
      return toast.warning("Enter Valid Password");
    if (oldPassword.length > 20 || newPassword.length > 20)
      return toast.error("Password Should be less than 20 Characters");
    else {
      if (oldPassword && newPassword) {
        const user = getUser();
        await changePassword({ oldPassword, newPassword, email: user });
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <AppBar
        sx={{
          background: "#3b77cdff",
          top: 0,
          position: "sticky",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => setAnchorElNav(event.currentTarget)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {newPage.map((page) => (
                  <MenuItem
                    key={page.id}
                    onClick={() => {
                      handleCloseNavMenu();
                      redirectPath(page.label);
                    }}
                  >
                    {page.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {newPage.map((page, index) => (
                <Button
                  key={page.id}
                  onClick={() => {
                    redirectPath(page.label);
                    setActiveIndex(index);
                    handleCloseNavMenu();
                  }}
                  sx={{
                    m: 0,
                    color: activeIndex === index ? "yellow" : "white",
                    display: "block",
                  }}
                >
                  {page.label}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0, display: "flex" }}>
              <Tooltip title="Open settings">
                <IconButton
                  onClick={(event) => setAnchorElUser(event.currentTarget)}
                  sx={{ borderRadius: "0" }}
                >
                  <p style={{ color: "white", fontSize: "12px" }}>
                    {userName}&nbsp;&nbsp;&nbsp;
                  </p>
                  <AccountCircleIcon
                    sx={{ bgcolor: "white", borderRadius: "50%" }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => {
                      handleCloseUserMenu();
                      handleSetting(setting);
                    }}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* // Change Password Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{" Change Password"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <TextField
            label="Old Password"
            value={oldPassword}
            sx={{ m: 1 }}
            error={oldPassword.length > 20 ? true : false}
            helperText={
              oldPassword.length > 20
                ? "Password Should be less than 20 Characters"
                : "Enter 8 Character Password"
            }
            onChange={(event) => {
              setOldpassword(event.target.value);
            }}
          />
          <TextField
            label="New Password"
            value={newPassword}
            sx={{ m: 1 }}
            error={newPassword.length > 20 ? true : false}
            helperText={
              newPassword.length > 20
                ? "Password Should be less than 20 Characters"
                : "Enter 8 Character Password"
            }
            onChange={(event) => {
              setNewpassword(event.target.value);
            }}
          />

          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updatePassword} autoFocus>
            Update Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
