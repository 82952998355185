import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Grid,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";

// Date and Time Pickers
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Components
import FilterBar from "../components/FilterBar";
import TableWrapper from "../components/TableWrapper";

// Utils
import { validateEmail, validateDate } from "../utils/helper";
import { getLogs } from "../utils/apicalls";

export default function LogsView() {
  const [actionValue, setactionValue] = useState([]);
  const [logfromDate, setLogfromDate] = useState(null);
  const [logtoDate, setLogToDate] = useState(null);
  const [filterEmail, setfilterEmail] = useState("");

  const [logFilter, setlogFilter] = useState({});
  const [open, setOpen] = useState(false);
  const [logPageSize, setlogPageSize] = useState(10);
  const [logdataCount, setlogdataCount] = useState();
  const [logcurrentPage, setCurrentPage] = useState(1);
  const [logTable, setLogTable] = useState([]);

  if (actionValue === null) setactionValue([]);
  if (logPageSize === null) setlogPageSize(10);

  const actionOption = [
    "change_permission",
    "change_password",
    "create_user",
    "get_number",
    "update_server_status",
    "banned_number",
    "update_device_count",
  ];

  const FilterOptions = [
    { name: "Search", method: handleSearch },
    { name: "Reset", method: handleReset },
  ];

  const TableMap = [
    { column: "Action", mapping: "action" },
    { column: "Date", mapping: "performAt" },
    { column: "Performed By", mapping: "performBy" },
  ];

  async function callApi() {
    const obj = {
      action: "",
      email: "",
      fromDate: "",
      toDate: "",
    };

    if (Object.entries(logFilter).length) {
      const { data, count } = await getLogs(
        logFilter,
        (logcurrentPage - 1) * logPageSize,
        logPageSize
      );
      setLogTable(data);
      setlogdataCount(count);
    } else {
      const { data, count } = await getLogs(
        obj,
        (logcurrentPage - 1) * logPageSize,
        logPageSize
      );
      setlogdataCount(count);
      setLogTable(data);
    }
  }

  useEffect(
    () => {
      callApi();
    },
    // eslint-disable-next-line
    [logFilter, logPageSize, logcurrentPage]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [logdataCount, logPageSize, logFilter]);

  async function handleSearchInternal() {
    let obj = { action: "", email: "", fromDate: "", toDate: "" };

    if (filterEmail && validateEmail(filterEmail, setfilterEmail))
      obj.email = filterEmail;
    if (actionValue.length) obj.action = actionValue;
    if (logfromDate)
      obj.fromDate = moment(logfromDate.$d).add(330, "m").toDate();
    if (logtoDate) obj.toDate = moment(logtoDate.$d).add(330, "m").toDate();

    if (!validateDate(logfromDate, logtoDate)) {
      obj.fromDate = "";
      obj.toDate = "";
    }

    setlogFilter(obj);
    setOpen(false);
  }

  function handleSearch() {
    setOpen(true);
  }

  async function handleReset() {
    setlogFilter({});
    setactionValue([]);
    setLogfromDate(null);
    setLogToDate(null);
    setfilterEmail("");
    setlogPageSize(10);
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <Grid container sx={{ px: 6 }}>
          <FilterBar FilterOptions={FilterOptions} />
          <TableWrapper
            columnMap={TableMap}
            data={logTable}
            count={logdataCount}
            currentPage={logcurrentPage}
            pageSize={logPageSize}
            setSize={setlogPageSize}
            setPage={setCurrentPage}
          />
        </Grid>
      </Box>

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filters</DialogTitle>
        {/* logFilter Options */}
        <Box
          px={5}
          sx={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="filterEmail"
                label="Email"
                sx={{ width: "100%" }}
                size="small"
                error={filterEmail.length > 20 ? true : false}
                helperText={filterEmail.length > 20 ? "Invalid email" : ""}
                value={filterEmail}
                onChange={(event) => {
                  setfilterEmail(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                id="action"
                size="small"
                options={actionOption}
                value={actionValue}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setactionValue(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="Action"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ display: "flex" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} xs={1}>
                <DatePicker
                  disableFuture
                  label="From Date"
                  value={logfromDate}
                  maxDate={logtoDate}
                  onChange={(newValue) => {
                    setLogfromDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%", mr: 1 }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs} xs={1}>
                <DatePicker
                  disableFuture
                  label="To Date"
                  minDate={logfromDate}
                  value={logtoDate}
                  onChange={(newValue) => {
                    setLogToDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%", ml: 1 }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleSearchInternal} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
