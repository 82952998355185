import "./App.css";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import SignIn from "./Signin/LoginForm";
import PrivateRoute from "./utils/privateRouter";
import Automation from "./containers/Automation";
import NotFound from "./Signin/NotFound";
import DashboardWrapper from "./containers/DashboardWrapper";
import UserDashboard from "./containers/Users";
import LogTable from "./containers/Logs";
import Server from "./containers/Server";
import Main from "./containers/Home";

const router = createBrowserRouter([
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <DashboardWrapper />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/",
        element: (
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        ),
      },
      {
        path: "/automation",
        element: (
          <PrivateRoute>
            <Automation />
          </PrivateRoute>
        ),
      },
      {
        path: "/users",
        element: (
          <PrivateRoute>
            <UserDashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/log",
        element: (
          <PrivateRoute>
            <LogTable />
          </PrivateRoute>
        ),
      },
      {
        path: "/server",
        element: (
          <PrivateRoute>
            <Server />
          </PrivateRoute>
        ),
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}
export default App;
