import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Grid,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

// Components
import FilterBar from "../components/FilterBar";
import ActionBar from "../components/ActionBar";
import StatsBar from "../components/StatsBar";
import TableWrapper from "../components/TableWrapper";

// Utils
import {
  getIpAddressList,
  addNewServer,
  getUser,
  searchServerList,
  editServer,
  changeServerStatusAPI,
} from "../utils/apicalls";

export default function ServerView() {
  const [ipAddress, setIpAddress] = useState("");
  const [serverStatus, setServerStatus] = useState([]);
  const [serverFilter, setserverFilter] = useState({});
  const [open, setOpen] = useState(false);
  const [serverPageSize, setServerPageSize] = useState(10);
  const [serverDataCount, setServerDataCount] = useState();
  const [serverCurrentPage, setCurrentPage] = useState(1);
  const [serverTable, setServerTable] = useState([]);
  const serverStatusOption = ["active", "inactive"];
  const [ipOption, setIpOption] = useState([]);

  // add Server
  const [newIpAddress, setnewIpAddress] = useState("");
  const [newServerStatus, setNewServerStatus] = useState([]);
  const [newcore, setNewCore] = useState("");
  const [newvirtualCore, setNewVirtualCore] = useState("");
  const [newRam, setnewRam] = useState("");
  const [newDeviceRequest, setnewDeviceRequest] = useState("");
  const [addServerDialog, setAddServerDialog] = useState(false);

  // edit server
  const [editIpAddress, setEditIpAddress] = useState("");
  const [editDeviceRequest, setEditDeviceRequest] = useState("");
  const [editDialog, setEditDialog] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const FilterOptions = [
    { name: "Search", method: handleSearch },
    { name: "Reset", method: handleReset },
  ];

  const ActionOptions = [
    {
      name: "Add Server",
      method: handleAddServerButton,
    },
  ];

  const TableMap = [
    { column: "IP", mapping: "ip" },
    { column: "Core", mapping: "core" },
    { column: "Virtual Core", mapping: "virtual_core" },
    { column: "Ram", mapping: "RAM" },
    { column: "Status", mapping: "status" },
    { column: "Server Capacity", mapping: "device_strength" },
    { column: "Running Device", mapping: "deviceRequest" },
    { column: "Action", mapping: "button", renderer: handleTableAction },
  ];

  const StatOptions = [{ name: "Total Servers", method: serverDataCount }];

  if (serverPageSize === null) setServerPageSize(10);

  async function callApi() {
    if (Object.entries(serverFilter).length) {
      const { data, count } = await searchServerList(
        serverFilter,
        (serverCurrentPage - 1) * serverPageSize,
        serverPageSize
      );
      setServerTable(data || []);
      setServerDataCount(count);
    } else {
      const obj = { ip: "", status: "" };
      let ipList = await getIpAddressList();
      const { data, count } = await searchServerList(
        obj,
        (serverCurrentPage - 1) * serverPageSize,
        serverPageSize
      );

      if (ipList.data) {
        ipList = ipList.data.map((item) => item.ip);
        setIpOption(ipList);
      }
      setServerTable(data || []);
      setServerDataCount(count);
    }
  }

  useEffect(
    () => {
      callApi();
    },
    // eslint-disable-next-line
    [serverFilter, serverPageSize, serverCurrentPage, isEdited]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [serverDataCount, serverPageSize, serverFilter]);

  async function handleSearchInternal() {
    const obj = {
      ip: ipAddress ? [ipAddress] : "",
      status: serverStatus?.length ? serverStatus : "",
    };
    setserverFilter(obj);
    setOpen(false);
  }

  async function handleSearch() {
    setOpen(true);
  }

  async function handleReset() {
    setserverFilter({});
    setIpAddress("");
    setServerStatus([]);
    setServerPageSize(10);
  }

  async function handleAddServer() {
    let obj = {};
    obj.ip = newIpAddress || "";
    obj.core = newcore || "";
    obj.virtual_core = newvirtualCore || "";
    obj.RAM = newRam || "";
    obj.status = newServerStatus || "";
    obj.deviceRequest = newDeviceRequest || "";
    obj.email = await getUser();
    await addNewServer(obj);
    setAddServerDialog(false);
    setIsEdited(!isEdited);
    setnewDeviceRequest("");
    setnewIpAddress("");
    setNewCore("");
    setNewVirtualCore("");
    setNewServerStatus([]);
    setnewDeviceRequest("");
  }

  function handleAddServerButton() {
    setAddServerDialog(true);
  }

  function handlenewRam(event) {
    const newValue = event.target.value;
    if (/^\d*\.?\d*$/.test(newValue)) setnewRam(newValue);
  }

  async function handleChangeServerStatus(ip, action) {
    await changeServerStatusAPI({
      ip,
      action: action === "active" ? "deactive" : "active",
    });
    setIsEdited(!isEdited);
  }

  function handleClickdialogBox(server) {
    setEditDialog(true);
    setEditIpAddress(server.ip);
    setEditDeviceRequest(server.deviceRequest);
    setIsEdited(false);
  }

  async function handleEditServer() {
    const status = await editServer({
      ip: editIpAddress,
      count: editDeviceRequest,
    });
    if (status) {
      setIsEdited(!isEdited);
      setEditDialog(false);
    }
  }

  function handleTableAction(item) {
    return (
      <>
        {item.status === "active" ? (
          <Button
            variant="contained"
            color="error"
            sx={{ position: "static", fontSize: "12px" }}
            onClick={() => {
              handleChangeServerStatus(item.ip, item.status);
            }}
          >
            Deactivate
          </Button>
        ) : (
          <Button
            variant="contained"
            color="success"
            sx={{ position: "static", fontSize: "12px" }}
            onClick={() => {
              handleChangeServerStatus(item.ip, item.status);
            }}
          >
            Activate
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{
            position: "static",
            fontSize: "12px",
            margin: "4px",
          }}
          onClick={() => {
            handleClickdialogBox(item);
          }}
        >
          <EditIcon />
        </Button>
      </>
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <Grid container sx={{ px: 6 }}>
          <FilterBar FilterOptions={FilterOptions} />
          <ActionBar ActionOptions={ActionOptions} />
          <StatsBar StatOptions={StatOptions} />
          <TableWrapper
            columnMap={TableMap}
            data={serverTable}
            count={serverDataCount}
            currentPage={serverCurrentPage}
            pageSize={serverPageSize}
            setSize={setServerPageSize}
            setPage={setCurrentPage}
          />
        </Grid>
      </Box>

      {/* Add Server Dialog */}
      <Dialog
        open={addServerDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Server Configuration</DialogTitle>

        {/* Add Server Options */}
        <Box
          px={5}
          sx={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="ip"
                label="IP Address"
                sx={{ width: "100%" }}
                size="small"
                error={newIpAddress?.length > 20 ? true : false}
                helperText={
                  newIpAddress?.length > 20 ? "Invalid Ip Address" : ""
                }
                value={newIpAddress}
                onChange={(event) => {
                  setnewIpAddress(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Core"
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={newcore}
                onChange={(e) => {
                  setNewCore(e.target.value.replace(/\D/g, ""));
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Virtual Core"
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={newvirtualCore}
                onChange={(e) => {
                  setNewVirtualCore(e.target.value.replace(/\D/g, ""));
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Ram"
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={newRam}
                placeholder={"in GB"}
                onChange={handlenewRam}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Device Count"
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={newDeviceRequest}
                onChange={(e) => {
                  setnewDeviceRequest(e.target.value.replace(/\D/g, ""));
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                id="action"
                size="small"
                options={serverStatusOption}
                value={newServerStatus}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setNewServerStatus(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="Server Status"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button
            onClick={() => {
              setAddServerDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleAddServer} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Server Filter */}
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filters</DialogTitle>
        {/* serverFilter Options */}
        <Box
          px={5}
          sx={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="action"
                size="small"
                options={ipOption}
                value={ipAddress}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setIpAddress(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="Ip Address"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                id="action"
                size="small"
                options={serverStatusOption}
                value={serverStatus}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setServerStatus(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="Server Status"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleSearchInternal} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Server */}
      <Dialog
        open={editDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Running Device</DialogTitle>

        <Box
          px={5}
          sx={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Device Count"
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={editDeviceRequest}
                onChange={(e) => {
                  setEditDeviceRequest(e.target.value.replace(/\D/g, ""));
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button
            onClick={() => {
              setEditDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleEditServer} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
