import axios from "axios";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import { decodeToken } from "react-jwt";
import "react-toastify/dist/ReactToastify.css";
import {
  LoginUrl,
  createUserUrl,
  OtpUrl,
  bannedNumUrl,
  imageCounterUrl,
  searchWebUrl,
  searchServerUrl,
  getIpAddressUrl,
  addServerUrl,
  searchUser,
  changeUserPermissionUrl,
  changePasswordUrl,
  LogsUrl,
  editServerUrl,
  changeServerStatusUrl,
} from "./urlConfig";
// Fetch data based on filters
let Token = window.localStorage.getItem("token");
if (Token) Token = Token.replaceAll('"', "");

const createConfig = (url, body, method) => {
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${Token}`,
    },
    data: body,
  };

  return config;
};

const fetchFromAPI = async (url, obj = {}, skip, limit) => {
  try {
    const data = { skip: skip || 0, limit: limit || 10 };
    const body = { ...data, ...obj };

    const config = createConfig(url, body, "post");
    const res = await axios(config);

    if (res.status === 200)
      return { data: res.data.data, count: res.data.count };
    throw new Error(res);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to Fetch Device List");
  }
};

// Download the data as per filter
const downloadApi = async (url, obj) => {
  try {
    const config = createConfig(url, obj, "post");
    const res = await axios(config);

    if (res.status === 200) {
      const blob = new Blob([res.data], { type: "text/csv;charset=utf-8" });
      return FileSaver.saveAs(blob, "Automation.csv");
    }

    throw new Error(res);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    else return toast.error("Failed to Download !!");
  }
};
// GET the total image Count
const imageCounter = async (obj) => {
  try {
    const config = createConfig(imageCounterUrl, obj, "post");
    const res = await axios(config);

    if (res.status === 200) return res.data.count;
    throw new Error(res);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed To Get Image Count");
  }
};

const getUser = () => {
  const token = window.localStorage.getItem("token");
  try {
    if (token) {
      const email = decodeToken(token).email;
      return email;
    }
  } catch (e) {
    return "";
  }
};

const getUserAllDetail = () => {
  try {
    const token = window.localStorage.getItem("token");
    if (token) return decodeToken(token);
    else return {};
  } catch (error) {
    return {};
  }
};

const AdduserAPI = async (obj = {}) => {
  try {
    const data = { ...obj, reqEmail: await getUser() };
    const config = createConfig(createUserUrl, data, "post");

    const userResponse = await axios(config);
    if (userResponse.status === 200) {
      toast.error(userResponse.msg);
      return { status: userResponse.status, msg: userResponse.data.message };
    }
    throw new Error(userResponse);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to Add User");
  }
};

const searchUserAPI = async (obj = {}, skip, limit) => {
  try {
    const data = { skip: skip || 0, limit: limit || 10 };
    const body = { ...data, ...obj };
    const config = createConfig(searchUser, body, "post");

    const res = await axios(config);
    if (res.status === 200)
      return { data: res.data.data, count: res.data.count };
    throw new Error(res);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to Search");
  }
};

const changeUserPermission = async (obj) => {
  try {
    const data = { ...obj, wa_stat: true, reqEmail: await getUser() };
    const config = createConfig(changeUserPermissionUrl, data, "post");

    const res = await axios(config);
    if (obj.isActive === undefined && res.status === 200)
      return toast.success(`Permission updated for ${obj.email}`);
    if (res.status === 200) return toast.success(res.data.message);

    throw new Error(res);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to Change Permission");
  }
};

async function changePassword(obj) {
  try {
    const config = createConfig(changePasswordUrl, { ...obj }, "post");
    const changedPassRes = await axios(config);
    if (changedPassRes.status === 200) {
      toast.success(changedPassRes.data.msg);
      toast.success("Please reLogin");
      localStorage.removeItem("token");
      setTimeout(() => {
        // HandleLogout
        window.location.href = "/";
      }, 2000);
      return true;
    }
    throw new Error(changedPassRes);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to Change Password");
  }
}

async function Login(obj) {
  try {
    const config = createConfig(LoginUrl, obj, "post");

    const loginResponse = await axios(config);
    if (loginResponse.status === 200) {
      const msg = loginResponse.data.message;
      const token = JSON.stringify(loginResponse.data.token);
      toast.success(msg);
      window.localStorage.setItem("token", token);
      window.location.href = "/";
      return;
    }

    throw new Error(loginResponse);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to Login. Please try again later.");
  }
}

const getLogs = async (obj = {}, skip, limit) => {
  try {
    const data = {
      skip: skip || 0,
      limit: limit || 10,
    };
    const body = { ...data, ...obj };
    const config = createConfig(LogsUrl, body, "post");

    const logResponse = await axios(config);
    if (logResponse.status === 200)
      return { data: logResponse.data.data, count: logResponse.data.count };
    throw new Error(logResponse);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to fetch Logs");
  }
};

const searchWeb = async (obj = {}, skip, limit) => {
  try {
    const data = {
      skip: skip || 0,
      limit: limit || 10,
    };
    const body = { ...data, ...obj };
    const config = createConfig(searchWebUrl, body, "post");
    const webRes = await axios(config);

    if (webRes.status === 200)
      return { data: webRes.data.data, count: webRes.data.count };
    throw new Error(webRes);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    toast.error("Failed to Search");
  }
};

const getMobileOtp = async (mobile, platform) => {
  try {
    const data = { mobile, platform, email: await getUser() };
    const config = createConfig(OtpUrl, data, "post");
    const OtpRes = await axios(config);
    if (OtpRes.status === 200) {
      if (OtpRes.data.otp)
        return toast.success(
          `${platform} OTP is ${OtpRes.data.otp} for ${mobile}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            toastId: "success1",
          }
        );
    }
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg)
      return toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        toastId: "success2",
      });
    toast.error("Failed to Get OTP");
  }
};

const banNumberApi = async (mobile, platform) => {
  try {
    const data = { mobile, platform, email: await getUser() };
    const config = createConfig(bannedNumUrl, data, "post");

    const banNumberRes = await axios(config);
    if (banNumberRes.status === 200)
      return toast.success(mobile + " has been banned");

    throw new Error(banNumberRes);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Unable to Ban Number !");
  }
};

const getIpAddressList = async () => {
  try {
    const config = createConfig(getIpAddressUrl, {}, "get");
    const ipResponse = await axios(config);
    if (ipResponse.status === 200)
      return { data: ipResponse.data.data, count: ipResponse.data.count };
    throw new Error(ipResponse);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) {
      toast.error(msg);
      return [];
    }
    return toast.error("Unable to Fetch IP Address");
  }
};

const addNewServer = async (obj = {}) => {
  try {
    const config = createConfig(addServerUrl, obj, "post");
    const addedServerResponse = await axios(config);
    if (addedServerResponse.status === 200) {
      const msg = addedServerResponse.data.msg;
      return toast.success(msg);
    }
    throw new Error(addedServerResponse);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to Add Server !!");
  }
};

const searchServerList = async (obj = {}, skip, limit) => {
  try {
    const data = { skip: skip || 0, limit: limit || 10 };
    const body = { ...data, ...obj };

    const config = createConfig(searchServerUrl, body, "post");
    const serverList = await axios(config);
    if (serverList.status === 200)
      return { data: serverList.data.data, count: serverList.data.count };
    throw new Error(serverList);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Unable to Get Servers !!");
  }
};

const editServer = async (obj = {}) => {
  try {
    const data = { ...obj, email: await getUser() };
    const config = createConfig(editServerUrl, data, "post");

    const editServerRes = await axios(config);
    if (editServerRes.status === 200) {
      toast.success(editServerRes.data.msg);
      return editServerRes.status;
    }
    throw new Error(editServerRes);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to Edit Server !!");
  }
};

const changeServerStatusAPI = async (obj = {}) => {
  try {
    const data = { ...obj, email: await getUser() };
    const config = createConfig(changeServerStatusUrl, data, "post");

    const changedServerRes = await axios(config);

    if (changedServerRes.status === 200) {
      toast.success(changedServerRes.data.msg);
      return changedServerRes.status;
    }

    throw new Error(changedServerRes);
  } catch (error) {
    const msg = error?.response?.data?.error;
    if (msg) return toast.error(msg);
    return toast.error("Failed to Change Status of Server !!");
  }
};

export {
  fetchFromAPI,
  downloadApi,
  imageCounter,
  addNewServer,
  searchWeb,
  editServer,
  searchServerList,
  getUser,
  getUserAllDetail,
  changeServerStatusAPI,
  AdduserAPI,
  Login,
  searchUserAPI,
  changeUserPermission,
  changePassword,
  getLogs,
  banNumberApi,
  getMobileOtp,
  getIpAddressList,
};
