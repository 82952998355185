import React from "react";
import { Grid, Box, Fab } from "@mui/material";

export default function StatsBar(props) {
  return (
    <Box sx={{ flexGrow: 1, width: "100%", py: 1 }}>
      <Grid container spacing={2} justifyContent="start">
        {props.StatOptions.map((item, index) => (
          <Grid key={index} item md={4} lg={3} xl={2.2}>
            <Fab
              variant="extended"
              size="small"
              color={item.color || "primary"}
              sx={{ width: "100%", fontSize: "12px" }}
            >
              {item.name}
              <span style={{ fontWeight: "900" }}>&nbsp; {item.method}</span>
            </Fab>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
