import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  Slider,
  Autocomplete,
  Typography,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
} from "@mui/material";

// Date and Time Pickers
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Components
import FilterBar from "../components/FilterBar";
import StatsBar from "../components/StatsBar";
import TableWrapper from "../components/TableWrapper";

// Utils
import {
  fetchFromAPI,
  downloadApi,
  imageCounter,
  getIpAddressList,
} from "../utils/apicalls";
import {
  validateMobile,
  validateDate,
  validateMobileToast,
  validateDeviceid,
  validateDeviceidToast,
} from "../utils/helper";
import { dockerListUrl, exportUrl } from "../utils/urlConfig";

export default function AutomationView() {
  const [mobile, setMobile] = useState("");
  const [deviceID, setdeviceID] = useState("");
  const [deviceStatus, setDeviceStatus] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [dpCount, setdpCount] = useState([0, 0]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setTodate] = useState(null);
  const [open, setOpen] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [lastDayImageCount, setlastDayImageCount] = useState(0);
  const [showRunningDeviceButton, setshowRunningDeviceButton] = useState(false);
  const [ipOption, setIpOption] = useState([]);
  const [filter, setFilter] = useState({});
  const [PageSize, setPageSize] = useState(10);
  const [dataCount, setdataCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  const FilterOptions = [
    { name: "Running", method: handleRunning },
    { name: "Search", method: handleSearch },
    { name: "Reset", method: handleReset },
    { name: "Export", method: handleExport },
  ];

  const StatOptions = [
    { name: "Total Devices", method: dataCount },
    { name: "Last Day Images", method: lastDayImageCount },
    { name: "Total Images", method: imageCount },
  ];

  const TableMap = [
    { column: "Mobile", mapping: "mobile" },
    { column: "Device ID", mapping: "port" },
    { column: "Task Status", mapping: "task" },
    { column: "Device Status", mapping: "deviceState" },
    { column: "Date", mapping: "createdAt" },
    { column: "Dp Count", mapping: "imageCount" },
    { column: "Contact Count", mapping: "contacts" },
    { column: "Ip Address", mapping: "ip" },
    {
      column: "Actions",
      mapping: "button",
      renderer: handleTableAction,
      disabled: !showRunningDeviceButton,
    },
  ];

  const deviceOption = ["running", "removed", "stopped", "not working"];
  const taskOption = ["complete", "incomplete"];

  if (deviceStatus === null) setDeviceStatus([]);
  if (taskStatus === null) setTaskStatus([]);
  if (PageSize === null) setPageSize(10);
  if (deviceStatus === null) setDeviceStatus([]);
  if (taskStatus === null) setTaskStatus([]);
  if (ipAddress == null) setIpAddress([]);

  async function callApi() {
    handleImageCount();
    const obj = {
      mobile: "",
      deviceID: "",
      ip: "",
      taskStatus: [],
      deviceStatus: [],
      fromDate: "",
      toDate: "",
      dpCount: [],
    };

    if (Object.entries(filter).length) {
      const { data, count } = await fetchFromAPI(
        dockerListUrl,
        filter,
        (currentPage - 1) * PageSize,
        PageSize
      );
      setlastDayImageCount();
      setTableData(data);
      setdataCount(count);
      if (filter.deviceStatus[0] === "running")
        return setshowRunningDeviceButton(true);
      return setshowRunningDeviceButton(false);
    } else {
      const { data, count } = await fetchFromAPI(
        dockerListUrl,
        obj,
        (currentPage - 1) * PageSize,
        PageSize
      );
      let ipList = await getIpAddressList();
      if (ipList.data) {
        ipList = ipList.data.map((item) => item.ip);
        setIpOption(ipList);
      }
      setshowRunningDeviceButton(false);
      setdataCount(count);
      setTableData(data);
    }
  }

  useEffect(() => {
    callApi();
    // eslint-disable-next-line
  }, [filter, PageSize, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dataCount, PageSize, filter]);

  async function handleImageCount() {
    const newImageCount = await imageCounter({ lastDayImageCount: false });
    const lastDayImagevalue = await imageCounter({ lastDayImageCount: true });
    setImageCount(newImageCount);
    setlastDayImageCount(lastDayImagevalue);
  }

  function handleMobile(event) {
    setMobile(event.target.value.replace(/\D/g, ""));
  }

  function handleDeviceId(event) {
    setdeviceID(event.target.value.replace(/\D/g, ""));
  }

  async function handleSearchInternal() {
    let obj = {};
    obj.deviceID = validateDeviceidToast(deviceID);
    obj.mobile = validateMobileToast(mobile);
    obj.deviceStatus = [];
    obj.taskStatus = [];
    obj.ip = "";
    if (deviceStatus.length) obj.deviceStatus.push(deviceStatus);
    if (taskStatus.length) obj.taskStatus.push(taskStatus);
    if (ipAddress.length) obj.ip = ipAddress;
    obj.dpCount = dpCount;

    if (dpCount[0] === 0 && dpCount[1] === 0) obj.dpCount = [];

    obj.fromDate = "";
    obj.toDate = "";
    if (fromDate) obj.fromDate = moment(fromDate.$d).add(330, "m").toDate();
    if (toDate) obj.toDate = moment(toDate.$d).add(330, "m").toDate();
    if (obj.hasOwnProperty("fromDate") && obj.hasOwnProperty("toDate")) {
      if (!validateDate(fromDate, toDate)) {
        obj.fromDate = "";
        obj.toDate = "";
      }
    }

    setFilter(obj);
    setOpen(false);
  }

  async function handleSearch() {
    setOpen(true);
  }

  async function handleExport() {
    await downloadApi(exportUrl, filter);
  }

  async function handleReset() {
    setdeviceID("");
    setdpCount([0, 0]);
    setMobile("");
    setDeviceStatus([]);
    setFilter({});
    setTaskStatus([]);
    setIpAddress("");
    setFromDate(null);
    setTodate(null);
    setPageSize(10);
  }

  async function handleRunning() {
    const runningObj = {
      mobile: "",
      deviceID: "",
      taskStatus: [],
      ip: "",
      deviceStatus: ["running"],
      fromDate: "",
      toDate: "",
      dpCount: [],
    };
    setFilter(runningObj);
  }

  function handleTableAction(item) {
    return (
      <>
        <Button
          disabled={!showRunningDeviceButton}
          variant="contained"
          component="a"
          href={`http://${item.ip}:${item.port}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          See Device
        </Button>
      </>
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <Grid container sx={{ px: 6 }}>
          <FilterBar FilterOptions={FilterOptions} />
          <StatsBar StatOptions={StatOptions} />
          <TableWrapper
            columnMap={TableMap}
            data={tableData}
            count={dataCount}
            currentPage={currentPage}
            pageSize={PageSize}
            setSize={setPageSize}
            setPage={setCurrentPage}
          />
        </Grid>
      </Box>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filters</DialogTitle>
        {/* Filter Options */}
        <Box
          px={5}
          sx={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="action"
                size="small"
                options={ipOption}
                value={ipAddress}
                disabled={mobile ? true : false}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setIpAddress(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="Ip Address"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Mobile Number"
                sx={{ width: "100%" }}
                disabled={deviceID ? true : false}
                size="small"
                type="number"
                error={!validateMobile(mobile)}
                helperText={validateMobile(mobile) ? "" : "Invalid Mobile"}
                value={mobile}
                onChange={handleMobile}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                size="small"
                label="Device Id"
                sx={{ width: "100%" }}
                type="number"
                disabled={mobile ? true : false}
                inputProps={{ maxLength: 4 }}
                error={!validateDeviceid(deviceID)}
                helperText={
                  validateDeviceid(deviceID) ? "" : "Invalid Device ID"
                }
                value={deviceID}
                variant="outlined"
                onChange={handleDeviceId}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                disabled={mobile || deviceID ? true : false}
                id="deviceStatus"
                size="small"
                options={deviceOption}
                value={deviceStatus}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setDeviceStatus(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    size="small"
                    {...params}
                    variant="outlined"
                    label="Device Status"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                disabled={deviceID || mobile ? true : false}
                id="taskStatus"
                size="small"
                options={taskOption}
                value={taskStatus}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                onChange={(_, value) => {
                  setTaskStatus(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="outlined"
                    label="Task Status"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Slider
                disabled={deviceID || mobile ? true : false}
                sx={{ color: "" }}
                value={dpCount}
                size="small"
                max={3000}
                valueLabelDisplay="on"
                onChange={(event, newValue) => {
                  setdpCount(newValue);
                }}
              />
              <Typography sx={{ opacity: 0.7 }}>DP Count</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} xs={1}>
                <DatePicker
                  disabled={deviceID || mobile ? true : false}
                  disableFuture
                  label="From Date"
                  value={fromDate}
                  maxDate={toDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%", mr: 1 }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs} xs={1}>
                <DatePicker
                  disabled={deviceID || mobile ? true : false}
                  disableFuture
                  label="To Date"
                  minDate={fromDate}
                  value={toDate}
                  onChange={(newValue) => {
                    setTodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%", ml: 1 }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleSearchInternal} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
